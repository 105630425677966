<template>
  <Layout class="ScoreOverview">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        :rules="rules"
      >
        <el-form-item>
          <el-date-picker
            v-model="formData.dateRange"
            type="datetimerange"
            :clearable="false"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item prop="idc_id">
          <el-select
            v-model="formData.idcId"
            v-loading="waitting"
            clearable
            :disabled="waitting"
            placeholder="请选择机房"
          >
            <el-option
              v-for="item in idcList"
              :key="item.idcId"
              :label="item.idcTitle + `(${item.idcId})`"
              :value="item.idcId"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.gameCount"
            clearable
            placeholder="用户使用次数"
          >
            <el-option
              v-for="item in useTimesList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="queryData"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="text"
          @click="goToScoreLog"
        >
          评分日志
        </el-button>
      </template>
    </BHeader>
    <div class="overview">
      <div class="items">
        <div class="num">
          {{ statisticsInfo.totalUserNum }}
        </div>
        <div class="name">
          累计评价用户
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ statisticsInfo.totalFeedbackNum }}
        </div>
        <div class="name">
          累计评价次数
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ statisticsInfo.gameExperienceAvgScore }}
        </div>
        <div class="name">
          游戏体验评分
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ statisticsInfo.gameAvgUseTime }}
        </div>
        <div class="name">
          平均体验时长
        </div>
      </div>
    </div>
    <h5>游戏体验评分</h5>
    <div
      v-loading="chartLoading"
      class="GameExperienceScore"
    >
      <SimpleEncode
        ref="SimpleEncode"
        height="350px"
        :chart-data="simpleEncodeData"
      />
    </div>
    <h5>游戏内容评分 - Top10</h5>
    <div
      v-loading="chartLoading"
      class="GameContentScore"
    >
      <StackedHorizontalBar
        ref="stackedHorizontalBar"
        height="450px"
        :chart-data="stackedHorizontalBarData"
      />
    </div>
  </Layout>
</template>

<script>
import { format } from 'fecha'
import { queryIdcList } from '@/api/machine.js'
import SimpleEncode from './components/SimpleEncode.vue'
import StackedHorizontalBar from './components/StackedHorizontalBar'
import { queryFeedbackStatics } from '@/api/netboom_score.js'
import { getYesterdaySection } from '@/utils'
const dateFormat = 'YYYY-MM-DD HH:mm:ss'

export default {
  name: 'ScoreNoCache',
  components: {
    SimpleEncode,
    StackedHorizontalBar
  },
  data () {
    return {
      formData: {
        dateRange: getYesterdaySection(),
        idc_id: '',
        useTimes: 1
      },
      rules: {},
      idcList: [],
      waitting: true,
      useTimesList: [
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
        { name: 10, value: 10 }
      ],
      statisticsInfo: {},
      simpleEncodeData: [],
      stackedHorizontalBarData: {},
      chartLoading: false
    }
  },
  created () {
    this.queryIdcList()
  },
  methods: {
    queryIdcList () {
      queryIdcList({})
        .then(res => {
          this.idcList = res.data.list
          if (this.idcList.length) {
            this.formData.idc_id = this.idcList[0].idcId
            this.queryData()
          }
        })
        .finally(() => {
          this.waitting = false
        })
    },
    queryData () {
      let startTime
      let endTime
      const [start, end] = this.formData.dateRange || []
      const { idcId, gameCount } = this.formData
      if (start) {
        startTime = format(new Date(start), dateFormat)
        endTime = format(new Date(end), dateFormat)
      }
      this.chartLoading = true
      queryFeedbackStatics({
        startTime,
        endTime,
        idcId,
        gameCount
      })
        .then(res => {
          if (res.code === 200) {
            const {
              totalUserNum,
              totalFeedbackNum,
              gameExperienceAvgScore,
              gameAvgUseTime,
              experienceList,
              top10List: { yAxis, list }
            } = res.data
            this.statisticsInfo = {
              totalUserNum,
              totalFeedbackNum,
              gameExperienceAvgScore,
              gameAvgUseTime
            }
            this.simpleEncodeData = experienceList
            this.stackedHorizontalBarData = {
              yAxis,
              list
            }
            this.$nextTick(() => {
              this.$refs.SimpleEncode.setOptions()
              this.$refs.stackedHorizontalBar.setOptions()
            })
          }
        })
        .finally(() => {
          this.chartLoading = false
        })
    },
    goToScoreLog () {
      this.$router.push({
        name: 'ScoreLog2'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .ScoreOverview {
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
        }
        .name {
          font-size: 12px;
        }
      }
    }
  }
</style>
