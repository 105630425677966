import request from '@/utils/request'

export function queryIdcList (data) {
  return request({
    url: '/gear/idcList',
    data
  })
}
export function queryImachineTrend (data) {
  return request({
    url: '/gear/machineTrend',
    data
  })
}
export function queryMachineUseLog (data) {
  return request({
    url: '/gear/machineUseLog',
    data
  })
}
