import request from '@/utils/request'

// 调度意见反馈列表
export function queryFeedbacks (data) {
  return request({
    url: '/log/admin/schedule/feedbacks',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}
// 调度意见反馈统计
export function queryFeedbackStatics (data) {
  return request({
    url: '/log/admin/statics/feedbacks',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}
