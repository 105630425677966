<template>
  <div
    :class="[className, 'line-charts-wrapper']"
    :style="{height:height,width:width}"
  />
</template>

<script>

import * as echarts from 'echarts'
import resize from '@/mixins/resize'

export default {
  name: 'SankeyChart',
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '450px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.initChart()
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el)
    },
    setOptions () {
      this.chart.clear()
      const { yAxis: yAxisData, list: dataList } = this.chartData

      const legendData = dataList.map(item => item.name)
      const series = dataList.map(item => ({
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        ...item
      }))

      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: yAxisData
        },
        series
      }
      )
    }
  }
}
</script>

<style scoped lang="less">
  .line-charts-wrapper {
    box-sizing: border-box;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
  }
</style>
