<template>
  <div
    :class="[className, 'line-charts-wrapper']"
    :style="{height:height,width:width}"
  />
</template>

<script>

import * as echarts from 'echarts'
import resize from '@/mixins/resize'

export default {
  name: 'SankeyChart',
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '450px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.initChart()
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el)
    },
    setOptions () {
      this.chart.clear()

      const datasetSource = [
        ['score', 'product'],
        ...this.chartData
      ]

      const tempList = datasetSource.slice(1).map(item => item[0])
      const MIN_VALUE = Math.min(...tempList)
      const MAX_VALUE = Math.max(...tempList)

      this.chart.setOption({
        dataset: {
          source: datasetSource
        },
        grid: { containLabel: true },
        xAxis: { name: '数量' },
        yAxis: { type: 'category' },
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: MIN_VALUE,
          max: MAX_VALUE,
          text: ['High Score', 'Low Score'],
          // Map the score column to color
          dimension: 0,
          inRange: {
            color: ['#65B581', '#FFCE34', '#FD665F']
          }
        },
        series: [
          {
            type: 'bar',
            encode: {
              // Map the "amount" column to X axis.
              x: 'score',
              // Map the "product" column to Y axis
              y: 'product'
            }
          }
        ]
      })
    }
  }
}
</script>

<style scoped lang="less">
  .line-charts-wrapper {
    box-sizing: border-box;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
  }
</style>
